import { z } from 'zod';

export function isTruthyPredicate<SomeType>(
  item: SomeType | boolean | null | undefined,
): item is SomeType {
  return !!item;
}

/**
 * @deprecated Use `@/app/(types)/page-props` instead.
 */
export type PageProps = {
  params: Record<string, string>;
  searchParams?: Record<string, string>;
};

export function typedObjectKeys<T extends object>(obj: T) {
  return Object.keys(obj) as Array<keyof T>;
}

/**
 * Creates a Zod schema for an object with keys representing each day of the week.
 *
 * @param schema - A Zod schema type to be used as the value type for each day of the week.
 * @returns A Zod object schema where each weekday (from Monday to Sunday) is validated
 *          using the provided schema.
 *
 * Example usage:
 * const numberSchema = z.number();
 * const weekdayNumberSchema = weekdaySchema(numberSchema);
 *
 */
export function weekdaySchema<T>(schema: z.ZodType<T>) {
  return z.object({
    friday: schema,
    monday: schema,
    saturday: schema,
    sunday: schema,
    thursday: schema,
    tuesday: schema,
    wednesday: schema,
  });
}
